// 日语
const jp = {
    "请输入手机号":"携帯番号を入力してください",
    "请输入密码":"パスワードを入力してください",
    "请输入推荐码":"推奨コードを入力してください",
    "确认注册":"登録の確認",
    "下载软件":"ソフトウェアのダウンロード",
    "下载APP":"APPのダウンロード",
    "注册账号":"アカウントの登録",
    "强大的交易性能，无缝的交易体验":"強力なトランザクションパフォーマンス、シームレスなトランザクション体験",
    "做多或做空 9000 多种产品，享受低点差和低佣金":"9000種類以上の製品を多く作ったり空にしたりして、低ポイント差と低コミッションを享受します",
    "合约交易":"契約取引",
    "损失可控":"損失制御可能",
    "币币交易":"貨幣取引",
    "引领币币交易创新模式，提供数种主流虚拟数字货币交易，致力于为数字货币的爱好者提供一个安全，公平，开放，高效的区块链数字资产交易平台":"貨幣取引の革新モデルをリードし、いくつかの主流の仮想デジタル通貨取引を提供し、デジタル通貨の愛好家に安全、公平、開放、効率的なブロックチェーンデジタル資産取引プラットフォームを提供することに力を入れている",
    "丰富的交易物种":"豊富な交易種",
    "支持30+种数字资产指数合约交易。任意多空，灵活杠杆，放大收益":"30+種のデジタル資産指数契約取引をサポートする。任意の多空、柔軟なレバレッジ、収益拡大",
    "多种数字资产结算":"複数のデジタル資産決済",
    "稳定币合约使用USDT作为保证金，币本位币使用数字资产作为保证金进行交易":"安定通貨契約は保証金としてUSDTを使用し、通貨本位通貨は保証金としてデジタル資産を使用して取引を行う",
    "高流动性有助于实现价格发现功能，紧盯币币交易市场。":"高い流動性は価格発見機能を実現し、貨幣取引市場を注視するのに役立つ。",
    "由行业内杰出的做市商提供流动性，同时提供了完整的VIP等级来降低您的交易成本":"業界内の優れたビジネスパーソンが流動性を提供し、取引コストを削減するための完全なVIPレベルを提供",
    "领先的流动性服务":"先進的な流動性サービス",
    "提供100+主流币种的币对，热门币对的流动性，顶级的买卖深度，连续的K线图。":"100+主流通貨の通貨ペア、人気通貨ペアの流動性、トップレベルの売買深さ、連続したK線図を提供します。",
    "专业的解决方案":"専門的なソリューション",
    "提供主流币交易流动性，平台币流动性解决方案。":"主流貨幣取引の流動性、プラットフォーム貨幣の流動性ソリューションを提供する。",
    "交易成本低":"取引コストが低い",
    "具有竞争性的阶梯式交易费率，交易量越大手续费越低":"競争的なステップ型取引レートは、取引量が大きいほど手数料が低くなる",
    "多样化的功能":"多様な機能",
    "年龄5+":"年齢5+",
    "提供安全，便捷的买卖方式":"安全で便利な売買方法を提供する",
    "下载":"ダウンロード",
    "介绍":"紹介",
    "作为一家公司，我们强调长期的安全性、可靠性和便利性。我们的主要产品和服务反映了这一愿景。先进的交易平台,更低的消费，更安全高效的保障，我们让每个人都能轻松拥有和交易资产":"会社として、長期的な安全性、信頼性、利便性を強調しています。私たちの主な製品とサービスはこのビジョンを反映しています。先進的な取引プラットフォーム、より低い消費、より安全で効率的な保障、私たちは誰もが簡単に資産を所有し、取引することができます",
}

export default jp;