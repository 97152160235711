<template>
  <div class="home">
    <el-backtop :bottom="10" :visibility-height="1" :right="20">
      <img
        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE2IDE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBvbHlnb24gZmlsbD0iI0ZGRkZGRiIgcG9pbnRzPSI4LDIuOCAxNiwxMC43IDEzLjYsMTMuMSA4LjEsNy42IDIuNSwxMy4yIDAsMTAuNyAiLz4KPC9zdmc+Cg=="
        alt=""
      />
    </el-backtop>
    <el-dialog :visible.sync="langShow" width="90%" :show-close="false" center>
      <changeLanguage :language="language" @call="changeLanguage" />
    </el-dialog>
    <div class="title">
      <img src="@/assets/img/logo.png" alt="" />
      <button class="language" @click="changeLanguage()"></button>
    </div>
    <div class="h-70"></div>
    <div class="top-bg">
      <h2>{{ $t("强大的交易性能，无缝的交易体验") }}</h2>
      <p>{{ $t("做多或做空 9000 多种产品，享受低点差和低佣金") }}</p>
      <div class="register" @click='$router.push("/reg")'>{{ $t("注册账号") }}</div>
      <div class="down" @click='$router.push("/down")'>{{ $t("下载APP") }}</div>
    </div>
    <div class="three">
      <div class="three-item" v-for="(item, index) in threeList" :key="index">
        <img class="img2" :src="item.img2" alt="" />
        <img class="img1" :src="item.img1" alt="" />
        <img class="img3" :src="item.img3" alt="" />
        <h2>{{ item.h2 }}</h2>
        <p>{{ item.p }}</p>
      </div>
    </div>
    <img src="@/assets/img/about-1.png" alt="" style="width: 100%" />
    <div class="text-left ml-20">
      <h2 class="my-50">{{ $t("合约交易") }}</h2>
      <p class="my-50">{{ $t("损失可控") }}</p>
    </div>
    <div class="number" v-for="(item, index) in numberList" :key="index" @click="clickIndex = index">
      <img :src="item.img" alt="" :class="{ 'click': index == clickIndex }"/>
      <CountTo :startVal="0" :endVal="item.count" :duration="3000" />
      <div>{{ item.text }}</div>
    </div>
    <h2 style="font-size: 20px">{{ $t("币币交易") }}</h2>
    <p style="margin: 50px 15px">
      {{
        $t(
          "引领币币交易创新模式，提供数种主流虚拟数字货币交易，致力于为数字货币的爱好者提供一个安全，公平，开放，高效的区块链数字资产交易平台"
        )
      }}
    </p>
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item
        :name="item.id"
        :title="item.title"
        v-for="(item, index) in fourList"
        :key="index"
      >
        <p style="font-size:16px;color:#878991">{{ item.p }}</p>
      </el-collapse-item>
    </el-collapse>
    <img src="@/assets/img/faqs-1.png" alt="" style="width: 100%;margin-top: 50px">
    <div class="foot"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import changeLanguage from "@/components/changeLanguage.vue";
import CountTo from "vue-count-to";
export default {
  name: "HomeView",
  components: {
    changeLanguage,
    CountTo,
  },
  data() {
    return {
      clickIndex:999,
      activeName: 1,
      language: [
        {
          text: "中文简体",
          value: "zh",
        },
        {
          text: "English",
          value: "en",
        },
        {
          text: "日本語",
          value: "jp",
        },
        {
          text: "中文繁体",
          value: "hk",
        },
      ],
      langShow: false,
      threeList: [
        {
          img1: require("@/assets/img/icon-1-1.png"),
          img2: require("@/assets/img/shape-11.png"),
          img3: require("@/assets/img/icon-1.png"),
          h2: this.$t("丰富的交易物种"),
          p: this.$t(
            "支持30+种数字资产指数合约交易。任意多空，灵活杠杆，放大收益"
          ),
        },
        {
          img1: require("@/assets/img/icon-1-2.png"),
          img2: require("@/assets/img/shape-21.png"),
          img3: require("@/assets/img/icon-2.png"),
          h2: this.$t("多种数字资产结算"),
          p: this.$t(
            "稳定币合约使用USDT作为保证金，币本位币使用数字资产作为保证金进行交易"
          ),
        },
        {
          img1: require("@/assets/img/icon-1-3.png"),
          img2: require("@/assets/img/shape-31.png"),
          img3: require("@/assets/img/icon-3.png"),
          h2: this.$t("高流动性有助于实现价格发现功能，紧盯币币交易市场。"),
          p: this.$t(
            "由行业内杰出的做市商提供流动性，同时提供了完整的VIP等级来降低您的交易成本"
          ),
        },
      ],
      numberList: [
        {
          img: require("@/assets/img/shape-1.png"),
          count: 5000,
          text: "Happy Clients",
        },
        {
          img: require("@/assets/img/shape-2.png"),
          count: 10000,
          text: "Trusted Users",
        },
        {
          img: require("@/assets/img/shape-3.png"),
          count: 100,
          text: "Projects Done",
        },
        {
          img: require("@/assets/img/shape-4.png"),
          count: 92,
          text: "Conversion Rates",
        },
      ],
      fourList: [
        {
          title: this.$t("领先的流动性服务"),
          p: this.$t(
            "提供100+主流币种的币对，热门币对的流动性，顶级的买卖深度，连续的K线图。"
          ),
          id: 1,
        },
        {
          title: this.$t("专业的解决方案"),
          p: this.$t("提供主流币交易流动性，平台币流动性解决方案。"),
          id: 2,
        },
        {
          title: this.$t("交易成本低"),
          p: this.$t("具有竞争性的阶梯式交易费率，交易量越大手续费越低"),
          id: 3,
        },
        {
          title: this.$t("多样化的功能"),
          p: this.$t("多样化的功能"),
          id: 4,
        },
      ],
    };
  },
  async created() {
    /* const data = await this.$axios("home/getGoodsList", {
      c_id: 5,
    }); */
  },
  methods: {
    changeLanguage() {
      this.langShow = !this.langShow;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.home {
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog{
  border-radius: 20px;
}
  .el-backtop {
    width: 50px;
    height: 50px;
    background-color: #000;
  }
  .el-collapse {
    border: none;
  }
 ::v-deep .el-collapse-item {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.14);
    margin: 15px;
    padding:20px 35px;
    border-radius: 15px;
    text-align: left;
    .el-collapse-item__header {
      border: none;
      font-size: 20px;
      font-weight: bold;
    }
    .el-collapse-item__header.is-active {
      color: #ff2782;
    }
     .el-collapse-item__wrap {
      border: none;
    }
    .el-collapse-item__arrow.is-active{
          transform: rotate(0);
    }
    .el-icon-arrow-right:before{
          content: "\f106";
    height: 25px;
    width: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 30px;
    font-family: fontawesome;
    }
  }
  .title {
    display: flex;
    padding: 20px;
    background-color: #fff;
    position: fixed;
    top: 0;
    z-index: 999;
    right: 0;
    left: 0;
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    img {
      width: 150px;
    }
    .language {
      width: 50px;
      height: 50px;
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 999999;
      background: url("../assets/img/yy.png") no-repeat #fff center;
      background-size: 30px;
      border-radius: 50px;
    }
  }
  .top-bg {
    height: 650px;
    background-image: url("../assets/img/hero-bg-1.png");
    background-position: center center;
    background-size: cover;
    color: #fff;
    overflow: hidden;
    h2 {
      margin: 80px 0 20px;
    }
    > div {
      margin: 20px;
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      line-height: 1;
      padding: 20px 20px;
      text-transform: uppercase;
      border-radius: 30px;
    }
    .register {
      background: #ff2782;
      border: 2px solid #ff60a3;
    }
    .down {
      border: 2px solid #fff;
    }
  }
  .three {
    margin: 20px auto;
    width: 92%;
    .three-item {
      box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.14);
      overflow: hidden;
      border-radius: 15px;
      position: relative;
      padding-bottom: 50px;
      margin-bottom: 30px;
      color: #000;
      text-align: center;
      .img1 {
        position: absolute;
        top: 0;
        left: -60px;
        opacity: 0.5;
      }
      .img2 {
        position: absolute;
        top: 0;
        left: 0;
      }
      .img3 {
        position: absolute;
        top: 76px;
        left: 50%;
        transform: translateX(-50%);
      }
      h2 {
        margin: 230px 30px 20px;
        font-size: 20px;
        line-height: 1;
      }
      p {
        line-height: 1.5;
        margin: 0 20px;
      }
    }
  }
  .number {
    position: relative;
    padding: 30px 0;
    text-align: center;
    border-radius: 15px 0 15px 15px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.14);
    margin-bottom: 30px;
    overflow: hidden;
    img {
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0.5;
    }
    .click{
      opacity: 1;
      transform: scale(1.4);
    }
    span {
      font-size: 48px;
      line-height: 50px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    div {
      font-size: 22px;
      line-height: 32px;
      font-weight: 500;
      color: #8b95a3;
      text-transform: capitalize;
    }
  }
  .foot{
    margin-top: 30px;
    height: 80px;
    background: url("../assets/img/footer-bg.jpg");
  }
}
</style>
