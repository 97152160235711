<template>
  <div class="change-language">
    <el-radio
      v-model="radio"
      :class="{
        'bg-purple-b': radio == item.value,
        'text-white': radio == item.value,
      }"
      :label="item.value"
      border
      v-for="(item, index) in language"
      :key="index"
      @click.native.prevent="changeLanguage(item.value)"
      >{{item.text}}</el-radio
    >
  </div>
</template>
<script>
export default {
  name: "changeLanguage",
  props: {
    language: {
      type: Array,
      default: () => [
       {
          text: "中文简体",
          value: "zh",
        },
        {
          text: "English",
          value: "en",
        },
        {
          text: "日本語",
          value: "jp",
        },
        {
          text: "中文繁体",
          value: "hk",
        },
      ],
    },
  },
  data() {
    return {
      radio: "zh",
    };
  },
  created() {
    this.radio = localStorage.getItem("lang") || "zh";
  },
  methods: {
    changeLanguage(lang) {
      this.radio = lang;
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang);
      this.$emit("call");
      //页面刷新
      this.$router.go(0);
    },
  },
};
</script>
<style lang="scss" scoped>
.change-language {
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background-color: #fff;
  .el-radio {
    margin: 5px 0 !important;
    padding: 10px 0 0 30px !important;
    border-radius: 30px;
  }
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #fff;
  }
}
</style>
