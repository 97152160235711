// 中文
const zh = {
    "请输入手机号":"請輸入手機號",
    "请输入密码":"請輸入密碼",
    "请输入推荐码":"請輸入推薦碼",
    "确认注册":"確認註冊",
    "下载软件":"下載軟體",
    "下载APP":"下載APP",
    "注册账号":"註冊帳號",
    "强大的交易性能，无缝的交易体验":"强大的交易效能，無縫的交易體驗",
    "做多或做空 9000 多种产品，享受低点差和低佣金":"做多或做空9000多種產品，享受低點差和低傭金",
    "合约交易":"合約交易",
    "损失可控":"損失可控",
    "币币交易":"幣幣交易",
    "引领币币交易创新模式，提供数种主流虚拟数字货币交易，致力于为数字货币的爱好者提供一个安全，公平，开放，高效的区块链数字资产交易平台":"引領幣幣交易創新模式，提供數種主流虛擬數位貨幣交易，致力於為數位貨幣的愛好者提供一個安全，公平，開放，高效的區塊鏈數位資產交易平臺",
    "丰富的交易物种":"豐富的交易物種",
    "支持30+种数字资产指数合约交易。任意多空，灵活杠杆，放大收益":"支持30+種數位資產指數合約交易。 任意多空，靈活杠杆，放大收益",
    "多种数字资产结算":"多種數位資產結算",
    "稳定币合约使用USDT作为保证金，币本位币使用数字资产作为保证金进行交易":"穩定幣合約使用USDT作為保證金，幣本位幣使用數位資產作為保證金進行交易",
    "高流动性有助于实现价格发现功能，紧盯币币交易市场。":"高流動性有助於實現價格發現功能，緊盯幣幣交易市場。",
    "由行业内杰出的做市商提供流动性，同时提供了完整的VIP等级来降低您的交易成本":"由行業內傑出的做市商提供流動性，同時提供了完整的VIP等級來降低您的交易成本",
    "领先的流动性服务":"領先的流動性服務",
    "提供100+主流币种的币对，热门币对的流动性，顶级的买卖深度，连续的K线图。":"提供100+主流幣種的幣對，熱門幣對的流動性，頂級的買賣深度，連續的K線圖。",
    "专业的解决方案":"專業的解決方案",
    "提供主流币交易流动性，平台币流动性解决方案。":"提供主流幣交易流動性，平臺幣流動性解決方案。",
    "交易成本低":"交易成本低",
    "具有竞争性的阶梯式交易费率，交易量越大手续费越低":"具有競爭性的階梯式交易費率，交易量越大手續費越低",
    "多样化的功能":"多樣化的功能",
    "年龄5+":"年齡5+",
    "提供安全，便捷的买卖方式":"提供安全，便捷的買賣管道",
    "下载":"下載",
    "介绍":"介紹",
    "作为一家公司，我们强调长期的安全性、可靠性和便利性。我们的主要产品和服务反映了这一愿景。先进的交易平台,更低的消费，更安全高效的保障，我们让每个人都能轻松拥有和交易资产":"作為一家公司，我們強調長期的安全性、可靠性和便利性。 我們的主要產品和服務反映了這一願景。 先進的交易平臺，更低的消費，更安全高效的保障，我們讓每個人都能輕鬆擁有和交易資產",
}

export default zh;