import Vue from 'vue'
import App from './App.vue'
import './element'// 引入element-ui
import './registerServiceWorker'
import _ from 'lodash'// 引入lodash
import "./assets/font/iconfont.css"
import router from './router'
import store from './store'
import i18n from "./i18n";
import getData from './network'// 导入封装的网络请求函数
import 'element-ui/lib/theme-chalk/index.css';
import { Message,Loading } from 'element-ui'
// 将Mesage挂载到vue原型对象上
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service
Vue.prototype.$axios = getData // 将封装的网络请求函数挂载到Vue原型上
Vue.config.productionTip = false
Vue.prototype._ = _// 将lodash挂载到Vue原型上 
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

