// 中文
const zh = {
    "请输入手机号":"请输入手机号",
    "请输入密码":"请输入密码",
    "请输入推荐码":"请输入推荐码",
    "确认注册":"确认注册",
    "下载软件":"下载软件",
    "下载APP":"下载APP",
    "注册账号":"注册账号",
    "强大的交易性能，无缝的交易体验":"强大的交易性能，无缝的交易体验",
    "做多或做空 9000 多种产品，享受低点差和低佣金":"多种产品，享受低点差和低佣金",
    "合约交易":"合约交易",
    "损失可控":"损失可控",
    "币币交易":"币币交易",
    "引领币币交易创新模式，提供数种主流虚拟数字货币交易，致力于为数字货币的爱好者提供一个安全，公平，开放，高效的区块链数字资产交易平台":"引领币币交易创新模式，提供数种主流虚拟数字货币交易，致力于为数字货币的爱好者提供一个安全，公平，开放，高效的区块链数字资产交易平台",
    "丰富的交易物种":"丰富的交易物种",
    "支持30+种数字资产指数合约交易。任意多空，灵活杠杆，放大收益":"支持30+种数字资产指数合约交易。任意多空，灵活杠杆，放大收益",
    "多种数字资产结算":"多种数字资产结算",
    "稳定币合约使用USDT作为保证金，币本位币使用数字资产作为保证金进行交易":"稳定币合约使用USDT作为保证金，币本位币使用数字资产作为保证金进行交易",
    "高流动性有助于实现价格发现功能，紧盯币币交易市场。":"高流动性有助于实现价格发现功能，紧盯币币交易市场。",
    "由行业内杰出的做市商提供流动性，同时提供了完整的VIP等级来降低您的交易成本":"由行业内杰出的做市商提供流动性，同时提供了完整的VIP等级来降低您的交易成本",
    "领先的流动性服务":"领先的流动性服务",
    "提供100+主流币种的币对，热门币对的流动性，顶级的买卖深度，连续的K线图。":"提供100+主流币种的币对，热门币对的流动性，顶级的买卖深度，连续的K线图。",
    "专业的解决方案":"专业的解决方案",
    "提供主流币交易流动性，平台币流动性解决方案。":"提供主流币交易流动性，平台币流动性解决方案。",
    "交易成本低":"交易成本低",
    "具有竞争性的阶梯式交易费率，交易量越大手续费越低":"具有竞争性的阶梯式交易费率，交易量越大手续费越低",
    "多样化的功能":"多样化的功能",
    "年龄5+":"年龄5+",
    "提供安全，便捷的买卖方式":"提供安全，便捷的买卖方式",
    "下载":"下载",
    "介绍":"介绍",
    "作为一家公司，我们强调长期的安全性、可靠性和便利性。我们的主要产品和服务反映了这一愿景。先进的交易平台,更低的消费，更安全高效的保障，我们让每个人都能轻松拥有和交易资产":"作为一家公司，我们强调长期的安全性、可靠性和便利性。我们的主要产品和服务反映了这一愿景。先进的交易平台",
}

export default zh;