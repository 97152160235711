import Vue from 'vue'
 
// loading框设置局部刷新，且所有请求完成后关闭loading框
let loading
let needLoadingRequestCount = 0 // 声明一个对象用于存储请求个数
function startLoading (targetdq) {
  loading = Vue.prototype.$loading({
    lock: true,
    text: '努力加载中...',
    background: 'rgba(255,255,255,.4)',
    target: targetdq // 设置加载动画区域,只有满足条件的第一个元素才会显示loading效果
  })
}
 
function endLoading () {
    loading.close()
}
 function showFullScreenLoading (targetdq) {
  if (needLoadingRequestCount === 0) {
    startLoading(targetdq)
  }
  needLoadingRequestCount++
}
 function hideFullScreenLoading () {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}
 
export  {
  showFullScreenLoading,
  hideFullScreenLoading
}
