import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'BitBay'
    }
  },
  {
    path: '/reg',
    name: 'reg',
    component: () => import('../views/Reg.vue'),
    meta: {
      title: 'Reg'
    }
  },
  {
    path: '/down',
    name: 'down',
    component: () => import('../views/Down.vue'),
    meta: {
      title: 'Download'
    }
  }
]
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'BitBay';
  next();
});

export default router
