import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
// 引入各个语言配置文件
import zh from './config/zh';
import en from './config/en';
import jp from './config/jp';
import hk from './config/hk';
// 创建vue-i18n实例i18n
const i18n = new VueI18n({
    // 设置默认语言
    locale:localStorage.getItem('lang') || 'en', //语言标识
    // 添加多语言(每一个语言标示对应一个语言文件)
    messages:{
        zh, //中文 
        en, //英文
        jp, //日语
        hk, //中文繁体
    }
})

export default i18n;