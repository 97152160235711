// 导入axios网络请求
import axios from "axios";
import { showFullScreenLoading, hideFullScreenLoading } from "@/untils/loading"; // 引入loading
import { Message } from "element-ui";
/* 自定义axios实例 */
let instance = axios.create({
  baseURL: "/api",
  // "withCredentials":true,
  timeout: 5000,
});
//添加请求拦截器，拦截150ms内重复请求
let lastRequestTime = 0;
let pendingRequest = null;
instance.interceptors.request.use(
   (config) =>{
    if (config.headers.isLoading !== false) {
      // 如果配置了isLoading: false，则不显示loading,默认所有请求都显示loading,region用来指定loading显示的区域,不配置则默认全屏显示loading
      showFullScreenLoading(config.headers.region);
    }
    //为所有请求添加时间戳
    // config.params = {
    //   ...config.params,
    //   t: Date.now(),
    // };
    const currentTime = Date.now();
    if (pendingRequest && currentTime - lastRequestTime < 150) {
      pendingRequest.cancel("Operation canceled by the user.");
    }
    lastRequestTime = currentTime;
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;
    pendingRequest = source;
    return config;
  },
   (error)=> {
    //请求错误时做些事
    hideFullScreenLoading();
    Message.error("请求超时");
    return Promise.reject(error);
  }
);
//添加响应拦截器
instance.interceptors.response.use( (response) =>{
  //对响应数据做些事
  hideFullScreenLoading();
  return response.data;
});

/* get */
function fetchGet(params) {
  return instance({
    ...params,
  });
}

//定义函数完成公共网路请求的封装
function startNetwork(params) {
  // params是一个对象，用来存储开发人员传递的网络请求参数
  return axios({
    baseURL: "/api",
    timeout: 2000,
    ...params,
  });
}
// startNetwork({});
const modules = require.context("./apis", true, /\.(js|ts)$/);
//获取modules对应的模块名称
const newModules = modules.keys().reduce((target, keyName) => {
  //获取文件的文件名, replace(re, "$1")--使用左侧正则指令中第一个括号中的内容去替换正则匹配的内容
  const fileName = keyName.replace(/^\.\/(\w+)\.(js|ts)$/, "$1");
  //获取模块内容
  const module = modules(keyName).default;
  //在所有的函数名称前面添加文件的名字
  for (let key in module) {
    target[fileName + "/" + key] = module[key];
  }
  return target;
}, {});
// const proxy = new Proxy(newModules, {
//     // target代表proxy操作的对象， keyName代表被操作的对象的属性名
//     async get(target, keyName){
//         //获取请求需要的参数配置
//         const params = target[keyName](target.meta ? target.meta:{});
//         //根据params进行对应的网络请求
//         const data = await startNetwork(params);
//         target.meta = null;
//         // 返回服务器响应结果
//         return data;
//     }
// });
const proxy = new Proxy(newModules, {
  // target代表proxy操作的对象， keyName代表被操作的对象的属性名
  async get(target, keyName) {
    //获取请求需要的参数配置
    let params = target[keyName](target.meta ? target.meta : {});
    params.params = {
      ...params.params,
    }
    //根据params进行对应的网络请求
    const data = await fetchGet(params);
    target.meta = null;
    // 返回服务器响应结果
    return data;
  },
});
// 定义函数获取指定的数据
async function getData(keyName, meta = null) {
  if (meta) {
    proxy.meta = meta;
  }
  return await proxy[keyName];
}

export default getData;
