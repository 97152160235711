// 英文
const en = {
    "请输入手机号":"Please enter your phone number",
    "请输入密码":"Please input a password",
    "请输入推荐码":"Please enter the recommendation code",
    "确认注册":"Confirm registration",
    "下载软件":"Download a software",
    "下载APP":"Download the APP",
    "注册账号":"Register an account",
    "强大的交易性能，无缝的交易体验":"Powerful trading performance, seamless trading experience",
    "做多或做空 9000 多种产品，享受低点差和低佣金":"Long or short over 9000 products, enjoy low spreads and commissions",
    "合约交易":"Contract trading",
    "损失可控":"Controllable losses",
    "币币交易":"Currency trading",
    "引领币币交易创新模式，提供数种主流虚拟数字货币交易，致力于为数字货币的爱好者提供一个安全，公平，开放，高效的区块链数字资产交易平台":"Leading the innovative mode of cryptocurrency trading, providing several mainstream virtual digital currency transactions, committed to providing a safe, fair, open, and efficient blockchain digital asset trading platform for cryptocurrency enthusiasts",
    "丰富的交易物种":"Rich trading species",
    "支持30+种数字资产指数合约交易。任意多空，灵活杠杆，放大收益":"Supports trading of over 30 types of digital asset index contracts. Arbitrarily long short, flexible leverage, amplifying returns",
    "多种数字资产结算":"Settlement of multiple digital assets",
    "稳定币合约使用USDT作为保证金，币本位币使用数字资产作为保证金进行交易":"Stablecoin contracts use USDT as margin, while the base currency uses digital assets as margin for trading",
    "高流动性有助于实现价格发现功能，紧盯币币交易市场。":"High liquidity helps achieve price discovery function and closely monitor the cryptocurrency trading market.",
    "由行业内杰出的做市商提供流动性，同时提供了完整的VIP等级来降低您的交易成本":"Liquidity is provided by outstanding market makers in the industry, while offering a full VIP rating to reduce your trading costs",
    "领先的流动性服务":"Leading liquidity services",
    "提供100+主流币种的币对，热门币对的流动性，顶级的买卖深度，连续的K线图。":"Provide 100+mainstream currency pairs, liquidity of popular currency pairs, top-level buying and selling depth, and continuous candlestick charts.",
    "专业的解决方案":"Professional solutions",
    "提供主流币交易流动性，平台币流动性解决方案。":"Provide mainstream currency trading liquidity and platform currency liquidity solutions.",
    "交易成本低":"Low transaction costs",
    "具有竞争性的阶梯式交易费率，交易量越大手续费越低":"Competitive tiered trading rates, with lower transaction fees as the trading volume increases",
    "多样化的功能":"Diversified functions",
    "年龄5+":"Age 5+",
    "提供安全，便捷的买卖方式":"Provide safe and convenient ways of buying and selling",
    "下载":"download",
    "介绍":"introduce",
    "作为一家公司，我们强调长期的安全性、可靠性和便利性。我们的主要产品和服务反映了这一愿景。先进的交易平台,更低的消费，更安全高效的保障，我们让每个人都能轻松拥有和交易资产":"As a company, we emphasize long-term safety, reliability, and convenience. Our main products and services reflect this vision. Advanced trading platform, lower consumption, safer and more efficient protection, we make it easy for everyone to own and trade assets",
}

export default en;