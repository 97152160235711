import { register } from "register-service-worker"

export default {
  getChildren(params){
    return {
      url:"jzw/search",
      params,
      headers:{
        region:".bg-green-b"//请求时加载loading的区域，默认全屏加载
      },
    }
  },
  getGoodsList(params){
    return {
      url:"/news/list",
      data:params,
      headers:{
        "content-type":"application/x-www-form-urlencoded"
      },
      method:"POST"
    }
  },
  register(params){
    return {
      url:"user/register",
      data:params,
      headers:{
        "content-type":"application/x-www-form-urlencoded"
      },
      method:"POST"
    }
  },
}